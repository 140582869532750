import LayoutPage from '@component/LayoutPage';
import { getStaticPaths as utilGetStaticPaths } from '@lib/fetch/getStaticPaths';
import { createStaticPropsFetcher } from '@lib/fetch/getStaticProps';
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';

const PageView: NextPage = () => <LayoutPage />;

export default PageView;

export const getStaticProps: GetStaticProps = createStaticPropsFetcher('page');

export const getStaticPaths: GetStaticPaths = async () =>
  await utilGetStaticPaths({ entrySection: ['page'] }, true);
